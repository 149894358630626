<script setup>
    const props = defineProps({
        offers: {
            type: Array,
            required: true,
        },

        showDetails: {
            type: Boolean,
            default: false,
        },

        shaking: {
            type: Object,
            default: {},
        },
        // Url of the offers list
        slug: {
            type: String,
            default: '',
        },
        // Name of the offers list
        name: {
            type: String,
            default: '',
        },
    });

    onMounted(() => {
        let listIndex = 0;

        if (props.offers?.length) {
            // GTM Page view - Product Listing
            dataLayer.push({
                event: 'ecommerce',
                event_name: 'view_item_list',
                ecommerce: {
                    item_list_id: props.slug,
                    item_list_name: props.name,
                    items: props.offers.map((offer) => {
                        return {
                            item_id: offer.id.toString(),
                            item_name: offer.title,
                            item_list_id: props.slug,
                            item_list_name: props.name,
                            index: listIndex++,
                            item_brand: offer.corporation.commercial_name || offer.corporation.name,
                            item_category: offer.category,
                            item_category5: offer.display_status == 'open' ? 'actif' : 'terminé',
                            location_id: offer.warehouse?.address_city,
                            price: parseFloat(offer.last_bid_amount),
                            item_pallets: offer.number_pallets?.toString() || '0',
                            item_products: offer.total_quantity?.toString(),
                            item_units: offer.total_sku?.toString(),
                            item_rrp: offer.total_rrp?.toString(),
                        };
                    }),
                }
            });
        }
    });
</script>

<template>
    <MarketplaceOffer
        v-for="(offer, index) in props.offers"
        :key="offer.id"
        :offer="offer"
        :show-details="props.showDetails"
        :shaking="props.shaking[offer.id]"
        :slug="props.slug"
        :name="props.name"
        :style="`order: ${(index + 1) * 2}`"
    />
</template>
