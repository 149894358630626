<script setup>
    import PrimevueDialog from 'primevue/dialog';

    const props = defineProps({
        /**
         * Activation mode for the popup.
         * Available values: 'disabled', 'delay', 'onExit'
         * Default: 'disabled'
         * Details:
         *    - 'disabled': popup is disabled
         *    - 'delay': popup is shown after a delay (5s)
         *    - 'onExit': popup is shown when the user is about to leave the page
         */
        activationMode: {
            type: String,
            default: 'disabled',
        }
    });

    const { $me } = useNuxtApp();

    const showLoginReminder = ref(false);

    const setCookie = () => {
        const cookie = useCookie('subscribe-popup');
        cookie.value = false;
    };

    const cookie = useCookie('subscribe-popup');
    if (cookie.value === undefined && process.client) {
        setTimeout(() => {
            if (props.activationMode === 'onExit') {
                // When the mouse leave the window, show the popup
                window.addEventListener('mouseout', (e) => {
                    if (e.toElement === null && e.relatedTarget === null && cookie.value === undefined) {
                        showLoginReminder.value = true;
                    }
                });
            } else if (props.activationMode === 'delay') {
                showLoginReminder.value = true;
            }
        }, 5000);
    }

    const gtmPopupClick = (name) => {
        setCookie();

        // GTM Click - CTA - Popup
        dataLayer.push({
            event: 'generic_event',
            event_name: 'click_popup',
            button_id: name,
            button_name: name,
        });
    }
</script>

<template>
    <PrimevueDialog
        v-if="! $me.check()"
        id="login-reminder"
        v-model:visible="showLoginReminder"
        :style="{ maxWidth: '32rem', marginInline: '.5rem' }"
        :draggable="false"
        modal
        @after-hide="setCookie"
    >
        <div id="login-reminder__content">
            <div id="login-reminder__icon">
                <Icon name="ic:baseline-account-circle" />
            </div>

            <div id="login-reminder__text">
                <h4>{{ $t('No account yet?') }}</h4>
                <p>{{ $t('By creating your free account on Stocklear, you can access and bid on any of the lots offered for sale each week.') }}</p>
            </div>

            <LoginButtons id="login-reminder__buttons" @track-click="gtmPopupClick" />
        </div>
    </PrimevueDialog>
</template>
